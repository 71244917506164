<template>
  <!-- :label="$vuetify.breakpoint.smAndDown ? $t('products.orderBy') : ''" -->
  <v-row no-gutters align="center" justify="end">
    <v-select
      color="primary"
      :items="awardsSortOptions ? awardsSortOptions : sortOptions"
      :value="sortFilter"
      @change="handleSortFilter"
      :label="$t('products.orderBy')"
      outlined
      dense
      hide-details
    >
      <template v-slot:append><v-icon>$chevronDown</v-icon></template>
    </v-select>
  </v-row>
</template>

<script>
export default {
  name: "ProductSortFilter",
  data() {
    return {
      sortOptions: [
        { value: "", text: "Rilevanza", icon: "ion-arrow-up-c" },
        { value: "alf_asc", text: "Descrizione", icon: "ion-arrow-up-c" },
        { value: "pri_asc", text: "Prezzo crescente", icon: "ion-arrow-up-c" },
        {
          value: "pri_des",
          text: "Prezzo decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_asc",
          text: "Prezzo al kg-lt crescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_des",
          text: "Prezzo al kg-lt descrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "promo",
          text: "In promozione",
          icon: "ion-arrow-up-c"
        }
      ]
    };
  },
  props: {
    sortFilter: { type: Object },
    awardsSortOptions: { type: Array, required: false }
  },
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    }
  },
  mounted() {
    if (this.awardsSortOptions) {
      this.handleSortFilter("alf_asc");
    }
  }
};
</script>

<style lang="scss"></style>
